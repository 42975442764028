export const marketplaceAddress = "0x660C56a3f42D9527324af8A51CBe78E8f2Db17aE";
export const auctionAddress = "0xFA2238eEb4E2E5612853BF29f925a36f971F9010";

export const manifoldOpenEditionAddress =
  "0x26BBEA7803DcAc346D5F5f135b57Cf2c752A02bE";

export const contractAddress = "0x81535eE50E22811789841Dcb240a8771F8179E79";

export const artistId = 3376;
export const artistAddress = "0xA90DC6D356B622b9783F5020722E6f92B663438F";

export const projectName = "Rhythms of the Subconscious";
export const projectLink = "https://pho.ninfa.io";

export const etherscanUrl = "https://etherscan.io";
export const basescanUrl = "https://basescan.org";

export const startingDate = 1718640000000;

export const artistName = "Pho";
export const bio = [
  "Photon Tide also known as Pho is a mixed-media artist known for his psychedelic and experimental art style. Pho explores the limits of human consciousness with his dream-inspired series as well as human emotions and what it means to feel.",
  "Pho wants you to not fear what is inside vour mind but to embrace it. his artworks have been exhibited in NFT-NYC as well as Art Basel. Pho has also sold on multiple crypto-native platforms such as Foundation, Superrare, and most recently through his own contract on Manifold",
];

export const ninfaUrl = "https://ninfa.io";

export const artistUrl = "https://ninfa.io/@PhoPhoPhoPhoPhoPho";
export const artistTwitter = "https://twitter.com/@photonisdead";
export const artistInsta = "https://www.instagram.com/photontide";

export const interviewPath = "/interview/A-conversation-with-Pho-1";
export const articlePath = "/article/Artistic-genesis-and-musical-influences-2";
export const standard = "ERC-721";
export const mintedOn = "Manifold";
export const totalPieces = "3";

export const validChainId = 1;

export const openEdition = {
  title: "Placeholder",
  description: "",
  price: 0,
  instanceId: 0,
  contractAddress: "0x0B4B102742A68B422B02563CFe226a3ba2bA3420",
  tokenId: 1,
  originalImageUrl: "",
  metadata: "",
  startDate: 1715097600,
  endDate: 1715184000,
} as const;

export const manifoldFee = 0.0005;

export const compressedImageBaseUrl = "https://images.ninfa.io/nfts/original";
